import React from 'react'
import {Link} from 'gatsby'

const numToMes = (dateString) => {
  const parte1 = dateString.slice(0, 2)
  const parte2 = dateString.slice(3, 5)
  const parte3 = dateString.slice(5)
  let parte1Mes = ''
  switch (parte1) {
    case '01':
      parte1Mes = 'Enero'
      break
    case '02':
      parte1Mes = 'Febrero'
      break
    case '03':
      parte1Mes = 'Marzo'
      break
    case '04':
      parte1Mes = 'Abril'
      break
    case '05':
      parte1Mes = 'Mayo'
      break
    case '06':
      parte1Mes = 'Junio'
      break
    case '07':
      parte1Mes = 'Julio'
      break
    case '08':
      parte1Mes = 'Agosto'
      break
    case '09':
      parte1Mes = 'Septiembre'
      break
    case '10':
      parte1Mes = 'Octubre'
      break
    case '11':
      parte1Mes = 'Noviembre'
      break
    case '12':
      parte1Mes = 'Diciembre'
      break
    default:
      parte1Mes = ''
  }
  return `${parte2} de ${parte1Mes}${parte3}`
}

const PostCard = ({posts}) => {
  return (
    <div className='container'>
      {posts
        .filter(post => post.node.frontmatter.templateKey === 'article-page')
        .map(({node: post}) => (
          <div
            className='content'
            style={{border: '1px solid #eaecee', padding: '2em 4em'}}
            key={post.id}
          >
            <p>
              <Link className='has-text-primary' to={post.fields.slug}>
                {post.frontmatter.title}
              </Link>
              <span> - </span>
              <small style={{fontStyle: 'italic'}}>{numToMes(post.frontmatter.date)}</small>
            </p>
            <p>
              {post.excerpt}
              <br />
              <br />
              <Link className='button is-small' to={post.fields.slug}>
                                Seguir Leyendo →
              </Link>
            </p>
          </div>
        ))}
    </div>
  )
}

export default PostCard
